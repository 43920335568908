import React, {Component} from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Card } from 'primereact/card';
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import { InputTextarea } from 'primereact/inputtextarea';
import KookboekZoekBalk from "./KookboekZoekBalk";
import { FileUpload } from 'primereact/fileupload';

export default class Kookboek extends Component<{},{recepten:any, highest_id:number, currentRecept:any, isediting:boolean, editingrecept:any, aantalEters:number, alleIngredienten:any}>{
    constructor(props:any){
        super(props)
        this.state = {
            recepten: [],
            highest_id: 0,
            currentRecept: '',
            isediting: false,
            editingrecept: '',
            aantalEters: 6,
            alleIngredienten: []
        }
    }

    componentDidMount(): void {
        this.getdata()
    }

    searchToObject() {
        var pairs = window.location.search.substring(1).split("&"),
          obj:any = {},
          pair,
          i;
      
        for ( i in pairs ) {
          if ( pairs[i] === "" ) continue;
      
          pair = pairs[i].split("=");
          obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
        }
      
        return obj;
      }
    
    findAllIngredients(){
        let ingredienten = []
        let ingredientenNamen:string[] = []
        for(let gerecht of this.state.recepten){
            for(let ingredient of JSON.parse(gerecht.ingredienten)){
                if(ingredientenNamen.includes(ingredient.ingredient) == false){
                    ingredientenNamen.push(ingredient.ingredient)
                    ingredienten.push({ingredient: ingredient.ingredient})
                }
            }
        }
        ingredienten.sort((a:any, b:any) => a.ingredient.localeCompare(b.ingredient))
        this.setState({alleIngredienten: ingredienten})
    }

    saveGerecht(){
        let newrecept = this.state.editingrecept
        newrecept.ID = parseInt(newrecept.ID)
        newrecept.recept = newrecept.recept.replace(/[""'']/g, '')
        const url = 'https://noordeinde-47.nl/eetlijst_api/api.php'
        const requestOptions = {
            method: "POST",
            body: JSON.stringify(newrecept)
          };
        fetch(url, requestOptions)
        .then((response) => {
            this.getdata()
            if(response.status === 200){
                this.setState({isediting: false, currentRecept: ''})
            }
            
        })
    }

    getdata(){
        const url = 'https://noordeinde-47.nl/eetlijst_api/api.php'
        const requestOptions = {
            method: "GET",
            "Content-Type": "application/json",
          };
          fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                let highest_id = parseInt(result.slice(-1)[0].ID)
                result.sort((a:any, b:any) => a.gerecht.localeCompare(b.gerecht))
                this.setState({recepten: result, highest_id: highest_id}, () => this.findAllIngredients())
            })
    }

    updateGerecht = (gerecht:any) => {
        if(gerecht.ID === undefined){
            window.history.pushState(null,'', '/?page=kookboek')
        }else{
            window.history.pushState(null,'', '/?page=kookboek&gerecht=' + gerecht.ID)
        }
        this.setState({currentRecept: gerecht})
    }

    render(): React.ReactNode {
        if(this.searchToObject().gerecht !== undefined){
            if(this.state.currentRecept.ID !== this.searchToObject().gerecht && this.state.recepten.length !== 0){
                if(this.state.recepten.find((element:any) => element.ID == this.searchToObject().gerecht) === undefined){
                    this.updateGerecht('')
                }else{
                    this.setState({currentRecept: this.state.recepten.find((element:any) => element.ID == this.searchToObject().gerecht)})
                }     
            }
        }

        let ingredientenarray = Array.apply(null, Array(30)).map(function () {})
        if(this.state.currentRecept == '' && this.state.isediting == false){
            return(
                <Card title='Huyschkookboek' style={{width:'100%', margin: 'auto', maxWidth:'800px'}}>
                    <KookboekZoekBalk alleIngredienten={this.state.alleIngredienten} updategerecht={this.updateGerecht} gerechten={this.state.recepten}></KookboekZoekBalk>
                    <h3>Alle gerechten:</h3>
                    <Accordion>
                        <AccordionTab header='Voorgerechten'>
                            <div style={{display:'flex', gap:'5px', flexWrap:'wrap'}}>
                                {this.state.recepten.map((gerecht:any) => {
                                    if(gerecht.type_gerecht == 'Voorgerecht'){
                                        return(
                                            <div className="hover-grow" onClick={() => this.updateGerecht(gerecht)} style={{background:'white',flexGrow:1, border:'1px solid #ced4da', padding:'4px 10px', borderRadius:'4px'}} key={gerecht.ID}>
                                                <h4>{gerecht.gerecht}</h4>
                                                <p style={{textAlign:'left'}}>Kok: {gerecht.kok}</p>
                                                <p style={{textAlign:'left'}}>Boek: {gerecht.boek}</p>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </AccordionTab>
                        <br />
                        <AccordionTab header='Hoofdgerechten'>
                            <div style={{display:'flex', gap:'5px', flexWrap:'wrap'}}>
                                {this.state.recepten.map((gerecht:any) => {
                                    if(gerecht.type_gerecht == 'Hoofdgerecht'){
                                        return(
                                            <div className="hover-grow" onClick={() => this.updateGerecht(gerecht)} style={{background:'white',flexGrow:1, border:'1px solid #ced4da', padding:'4px 10px', borderRadius:'4px'}} key={gerecht.ID}>
                                                <h4>{gerecht.gerecht}</h4>
                                                <p style={{textAlign:'left'}}>Kok: {gerecht.kok}</p>
                                                <p style={{textAlign:'left'}}>Boek: {gerecht.boek}</p>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </AccordionTab>
                        <br />
                        <AccordionTab header='Nagerechten'>
                            <div style={{display:'flex', gap:'5px', flexWrap:'wrap'}}>
                                {this.state.recepten.map((gerecht:any) => {
                                    if(gerecht.type_gerecht == 'Nagerecht'){
                                        return(
                                            <div className="hover-grow" onClick={() => this.updateGerecht(gerecht)} style={{background:'white',flexGrow:1, border:'1px solid #ced4da', padding:'4px 10px', borderRadius:'4px'}} key={gerecht.ID}>
                                                <h4>{gerecht.gerecht}</h4>
                                                <p style={{textAlign:'left'}}>Kok: {gerecht.kok}</p>
                                                <p style={{textAlign:'left'}}>Boek: {gerecht.boek}</p>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </AccordionTab>
                        <AccordionTab header='Bijgerechten/BBQ'>
                            <div style={{display:'flex', gap:'5px', flexWrap:'wrap'}}>
                                {this.state.recepten.map((gerecht:any) => {
                                    if(gerecht.type_gerecht == 'Bijgerecht' || gerecht.type_gerecht == 'BBQ'){
                                        return(
                                            <div className="hover-grow" onClick={() => this.updateGerecht(gerecht)} style={{background:'white',flexGrow:1, border:'1px solid #ced4da', padding:'4px 10px', borderRadius:'4px'}} key={gerecht.ID}>
                                                <h4>{gerecht.gerecht}</h4>
                                                <p style={{textAlign:'left'}}>Kok: {gerecht.kok}</p>
                                                <p style={{textAlign:'left'}}>Boek: {gerecht.boek}</p>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </AccordionTab>
                        <AccordionTab header='Lunchgerechten'>
                            <div style={{display:'flex', gap:'5px', flexWrap:'wrap'}}>
                                {this.state.recepten.map((gerecht:any) => {
                                    if(gerecht.type_gerecht == 'Lunch'){
                                        return(
                                            <div className="hover-grow" onClick={() => this.updateGerecht(gerecht)} style={{background:'white',flexGrow:1, border:'1px solid #ced4da', padding:'4px 10px', borderRadius:'4px'}} key={gerecht.ID}>
                                                <h4>{gerecht.gerecht}</h4>
                                                <p style={{textAlign:'left'}}>Kok: {gerecht.kok}</p>
                                                <p style={{textAlign:'left'}}>Boek: {gerecht.boek}</p>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </AccordionTab>
                        <AccordionTab header='Drank'>
                            <div style={{display:'flex', gap:'5px', flexWrap:'wrap'}}>
                                {this.state.recepten.map((gerecht:any) => {
                                    if(gerecht.type_gerecht == 'Drank'){
                                        return(
                                            <div className="hover-grow" onClick={() => this.updateGerecht(gerecht)} style={{background:'white',flexGrow:1, border:'1px solid #ced4da', padding:'4px 10px', borderRadius:'4px'}} key={gerecht.ID}>
                                                <h4>{gerecht.gerecht}</h4>
                                                <p style={{textAlign:'left'}}>Kok: {gerecht.kok}</p>
                                                <p style={{textAlign:'left'}}>Boek: {gerecht.boek}</p>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <br />
                    <Button style={{display:'none'}} onClick={() => this.setState({isediting: true, editingrecept:{
                        ID: (this.state.highest_id + 1).toString(),
                        afbeelding: '',
                        boek: '',
                        gerecht: '',
                        ingredienten: '[{"ingredient":"","hoeveelheid":"","eenheid":""}]',
                        kok: '',
                        recept: '',
                        type_gerecht: ''
                    }})} label="Recept toevoegen"></Button>
                </Card>
            )
        }else if(this.state.isediting == true){
            return(
                <Card style={{width:'90%', margin: 'auto', maxWidth:'800px'}} title='Recept aanpassen'>
                    <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', gridGap:'10px'}}>
                        <Button style={{width:'100%'}} label="Terug naar recepten" onClick={() => {
                            this.updateGerecht('')
                            this.setState({isediting:false})}
                            }></Button>
                        <Button style={{width:'100%'}} onClick={() => this.saveGerecht()} label="Opslaan"></Button>
                    </div>
                    <br />
                    <FloatLabel>
                        <InputText style={{width:'100%'}} id='gerecht' onChange={(e:any) => {
                            let temp = this.state.editingrecept
                            temp.gerecht = e.target.value
                            this.setState({editingrecept: temp})
                        }} value={this.state.editingrecept.gerecht}></InputText>
                        <label htmlFor="gerecht">Gerecht: </label>
                    </FloatLabel>
                    <br />
                    <FloatLabel>
                        <label htmlFor="kok">Kok: </label>
                        <InputText onChange={(e:any) => {
                            let temp = this.state.editingrecept
                            temp.kok = e.target.value
                            this.setState({editingrecept: temp})
                        }} style={{width:'100%'}} id='kok' value={this.state.editingrecept.kok}></InputText>
                    </FloatLabel>
                    <br />
                    <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', gridGap:'10px'}}>
                        <FloatLabel>
                            <label htmlFor="type_gerecht">Type gerecht: </label>
                            <Dropdown onChange={(e) => {
                                let temp = this.state.editingrecept
                                temp.type_gerecht = e.value
                                this.setState({editingrecept: temp})
                            }} optionLabel="name" optionValue="name" value={this.state.editingrecept.type_gerecht} style={{width:'100%', backgroundColor:'#00000000'}} id="type_gerecht" options={[{name: 'Voorgerecht'}, {name: 'Hoofdgerecht'}, {name: 'Nagerecht'}, {name: 'Bijgerecht'}, {name: 'Lunch'}, {name: 'Drank'}, {name: 'BBQ'}]}></Dropdown>
                        </FloatLabel>
                        <FloatLabel>
                            <label htmlFor="boek">Kookboek: </label>
                            <Dropdown onChange={(e) => {
                                let temp = this.state.editingrecept
                                temp.boek = e.value
                                this.setState({editingrecept: temp})
                            }} optionLabel="name" optionValue="name" value={this.state.editingrecept.boek} style={{width:'100%', backgroundColor:'#00000000'}} id="boek" options={[{name: '2e Lustrum Kookboek'}, {name: '5e Lustrum Kookboek'}, {name: '7e Lustrum Kookboek'}, {name: 'Geen Kookboek'}]}></Dropdown>
                        </FloatLabel>
                    </div>
                    <div>
                            <br />
                            {ingredientenarray.map((nul, index) => {
                                if(JSON.parse(this.state.editingrecept.ingredienten).length == 0){
                                    let temp = this.state.editingrecept
                                    temp.ingredienten = JSON.stringify([{ingredient:'', hoeveelheid:'', eenheid:''}])
                                    this.setState({editingrecept: temp})
                                }
                                if(index==0){
                                    return(
                                        <div style={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr', gridGap:'10px', marginBottom:'20px'}}>
                                            <FloatLabel>
                                                <label htmlFor={'ingredient' + index.toString()}>Ingredient: </label>
                                                <InputText style={{width:'100%'}} value={JSON.parse(this.state.editingrecept.ingredienten)[0].ingredient} onChange={(e) => {
                                                    let temp = this.state.editingrecept
                                                    let tempingredienten = JSON.parse(temp.ingredienten)
                                                    tempingredienten[0].ingredient = e.target.value
                                                    if(tempingredienten[index].ingredient == '' && tempingredienten[index].hoeveelheid == '' && tempingredienten[index].eenheid == '' && tempingredienten.length > 0){
                                                        tempingredienten.splice(index, 1)
                                                    }
                                                    temp.ingredienten = JSON.stringify(tempingredienten)
                                                    this.setState({editingrecept: temp})
                                                }} id={'ingredient' + index.toString()}></InputText>
                                            </FloatLabel>
                                            <FloatLabel>
                                                <label htmlFor={'hoeveelheid' + index.toString()}>Hoeveelheid: </label>
                                                <InputText style={{width:'100%'}} value={JSON.parse(this.state.editingrecept.ingredienten)[0].hoeveelheid} onChange={(e) => {
                                                    let temp = this.state.editingrecept
                                                    let tempingredienten = JSON.parse(temp.ingredienten)
                                                    tempingredienten[0].hoeveelheid = e.target.value
                                                    if(tempingredienten[index].ingredient == '' && tempingredienten[index].hoeveelheid == '' && tempingredienten[index].eenheid == '' && tempingredienten.length > 0){
                                                        tempingredienten.splice(index, 1)
                                                    }
                                                    temp.ingredienten = JSON.stringify(tempingredienten)
                                                    this.setState({editingrecept: temp})
                                                }} id={'hoeveelheid' + index.toString()}></InputText>
                                            </FloatLabel>
                                            <FloatLabel>
                                                <label htmlFor={'eenheid' + index.toString()}>Eenheid: </label>
                                                <InputText style={{width:'100%'}} value={JSON.parse(this.state.editingrecept.ingredienten)[0].eenheid} onChange={(e) => {
                                                    let temp = this.state.editingrecept
                                                    let tempingredienten = JSON.parse(temp.ingredienten)
                                                    tempingredienten[0].eenheid = e.target.value
                                                    if(tempingredienten[index].ingredient == '' && tempingredienten[index].hoeveelheid == '' && tempingredienten[index].eenheid == '' && tempingredienten.length > 0){
                                                        tempingredienten.splice(index, 1)
                                                    }
                                                    temp.ingredienten = JSON.stringify(tempingredienten)
                                                    this.setState({editingrecept: temp})
                                                }} id={'eenheid' + index.toString()}></InputText>
                                            </FloatLabel>
                                        </div>
                                    )
                                }else if(JSON.parse(this.state.editingrecept.ingredienten)[index] != undefined){
                                    return(
                                        <div style={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr', gridGap:'10px', marginBottom:'20px'}}>
                                            <FloatLabel>
                                                <label htmlFor={'ingredient' + index.toString()}>Ingredient: </label>
                                                <InputText style={{width:'100%'}} value={JSON.parse(this.state.editingrecept.ingredienten)[index].ingredient} onChange={(e) => {
                                                    let temp = this.state.editingrecept
                                                    let tempingredienten = JSON.parse(temp.ingredienten)
                                                    tempingredienten[index].ingredient = e.target.value
                                                    if(tempingredienten[index].ingredient == '' && tempingredienten[index].hoeveelheid == '' && tempingredienten[index].eenheid == '' && tempingredienten.length > 0){
                                                        tempingredienten.pop()
                                                    }
                                                    temp.ingredienten = JSON.stringify(tempingredienten)
                                                    this.setState({editingrecept: temp})
                                                }} id={'ingredient' + index.toString()}></InputText>
                                            </FloatLabel>
                                            <FloatLabel>
                                                <label htmlFor={'hoeveelheid' + index.toString()}>Hoeveelheid: </label>
                                                <InputText style={{width:'100%'}} value={JSON.parse(this.state.editingrecept.ingredienten)[index].hoeveelheid} onChange={(e) => {
                                                    let temp = this.state.editingrecept
                                                    let tempingredienten = JSON.parse(temp.ingredienten)
                                                    tempingredienten[index].hoeveelheid = e.target.value
                                                    if(tempingredienten[index].ingredient == '' && tempingredienten[index].hoeveelheid == '' && tempingredienten[index].eenheid == '' && tempingredienten.length > 0){
                                                        tempingredienten.pop()
                                                    }
                                                    temp.ingredienten = JSON.stringify(tempingredienten)
                                                    this.setState({editingrecept: temp})
                                                }} id={'hoeveelheid' + index.toString()}></InputText>
                                            </FloatLabel>
                                            <FloatLabel>
                                                <label htmlFor={'eenheid' + index.toString()}>Eenheid: </label>
                                                <InputText style={{width:'100%'}} value={JSON.parse(this.state.editingrecept.ingredienten)[index].eenheid} onChange={(e) => {
                                                    let temp = this.state.editingrecept
                                                    let tempingredienten = JSON.parse(temp.ingredienten)
                                                    tempingredienten[index].eenheid = e.target.value
                                                    if(tempingredienten[index].ingredient == '' && tempingredienten[index].hoeveelheid == '' && tempingredienten[index].eenheid == '' && tempingredienten.length > 0){
                                                        tempingredienten.pop()
                                                    }
                                                    temp.ingredienten = JSON.stringify(tempingredienten)
                                                    this.setState({editingrecept: temp})
                                                }} id={'eenheid' + index.toString()}></InputText>
                                            </FloatLabel>
                                        </div>
                                    )
                                }else if(JSON.parse(this.state.editingrecept.ingredienten)[index - 1] != undefined){
                                    return(
                                        <div style={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr', gridGap:'10px', marginBottom:'20px'}}>
                                            <FloatLabel>
                                                <label htmlFor={'ingredient' + index.toString()}>Ingredient: </label>
                                                <InputText style={{width:'100%'}} value={''} onChange={(e) => {
                                                    let temp = this.state.editingrecept
                                                    let tempingredienten = JSON.parse(temp.ingredienten)
                                                    tempingredienten.push({ingredient: e.target.value, hoeveelheid:'', eenheid:''})
                                                    temp.ingredienten = JSON.stringify(tempingredienten)
                                                    this.setState({editingrecept: temp})
                                                }} id={'ingredient' + index.toString()}></InputText>
                                            </FloatLabel>
                                            <FloatLabel>
                                                <label htmlFor={'hoeveelheid' + index.toString()}>Hoeveelheid: </label>
                                                <InputText style={{width:'100%'}} value={''} onChange={(e) => {
                                                    let temp = this.state.editingrecept
                                                    let tempingredienten = JSON.parse(temp.ingredienten)
                                                    tempingredienten.push({ingredient:'', hoeveelheid:e.target.value, eenheid:''})
                                                    temp.ingredienten = JSON.stringify(tempingredienten)
                                                    this.setState({editingrecept: temp})
                                                }} id={'hoeveelheid' + index.toString()}></InputText>
                                            </FloatLabel>
                                            <FloatLabel>
                                                <label htmlFor={'eenheid' + index.toString()}>Eenheid: </label>
                                                <InputText style={{width:'100%'}} value={''} onChange={(e) => {
                                                    let temp = this.state.editingrecept
                                                    let tempingredienten = JSON.parse(temp.ingredienten)
                                                    tempingredienten.push({ingredient:'', hoeveelheid:'', eenheid:e.target.value})
                                                    temp.ingredienten = JSON.stringify(tempingredienten)
                                                    this.setState({editingrecept: temp})
                                                }} id={'eenheid' + index.toString()}></InputText>
                                            </FloatLabel>
                                        </div>
                                    )
                                }
                            })}
                    </div>
                    <FloatLabel>
                        <InputTextarea autoResize={true} style={{width:'100%'}} id="recept" value={this.state.editingrecept.recept} onChange={(e) => {
                            let temp = this.state.editingrecept
                            temp.recept = e.target.value
                            this.setState({editingrecept: temp})
                        }} rows={5} cols={30} />
                        <label htmlFor="recept">Recept:</label>
                    </FloatLabel>
                </Card>
            )
        }else{
            let receptimage = <img style={{width:'90%', maxWidth:'400px', margin:'auto', borderRadius:'20px'}} src={'https://www.noordeinde-47.nl/eetlijst_api/plaatjes/' + this.state.currentRecept.gerecht + '.png'} alt="" />
            return(
                <Card title={this.state.currentRecept.gerecht} style={{width:'90%', margin: 'auto', maxWidth:'800px'}}>
                    <Button style={{width:'100%'}} label="Terug naar recepten" onClick={() => {
                        this.updateGerecht('')
                        this.setState({isediting:false})}
                }></Button>
                    <Button style={{width:'100%', display:'none'}} onClick={() => {
                        this.updateGerecht('')
                        this.setState({isediting:true, editingrecept:this.state.currentRecept})
                        }} label="Aanpassen"></Button>
                    <div style={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr', gridGap:'20px'}}>
                        <h6>{this.state.currentRecept.type_gerecht}</h6>
                        <h6>{this.state.currentRecept.kok}</h6>
                        <h6>{this.state.currentRecept.boek}</h6>
                    </div>
                    <Dropdown onChange={(e) => {
                        this.setState({aantalEters: parseInt(e.value)})
                    }} optionLabel="name" optionValue="name" value={this.state.aantalEters.toString()} style={{width:'50%', backgroundColor:'#00000000', textAlign:'left'}} id="boek" options={[{name: '1'}, {name: '2'}, {name: '3'}, {name: '4'}, {name: '5'}, {name: '6'}, {name: '7'}, {name: '8'}, {name: '9'}, {name: '10'}, {name: '11'}, {name: '12'}, {name: '13'}, {name: '14'}, {name: '15'}, {name: '16'}, {name: '17'}, {name: '18'}, {name: '19'}, {name: '20'}]}></Dropdown>
                    <ul style={{columns:2}}>
                    {JSON.parse(this.state.currentRecept.ingredienten).map((ingredient:any, index:number) => {
                    if(ingredient.hoeveelheid != ""){
                        return(
                            <li style={{textAlign:'left'}} key={index}>
                                {ingredient.ingredient} {Math.round(ingredient.hoeveelheid / 6 * this.state.aantalEters * 100) / 100} {ingredient.eenheid}
                            </li>
                        )
                    }else{
                        return(
                            <li style={{textAlign:'left'}} key={index}>
                            {ingredient.ingredient} {ingredient.eenheid}
                            </li>
                        )
                    }
                    })}
                    </ul>
                    <p style={{whiteSpace: 'pre-line', textAlign:'left'}}>{this.state.currentRecept.recept}</p>
                    {receptimage}
                </Card>
            )
        }
    }
}