import React, {Component} from "react";

import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';

const namen = ['Dwight', 'Edske(Freddy H.)', 'Epke', 'Gleuf', 'Goofy', 'Heinz', 'Hubo', 'Jeff', 'Jeltsin', 'Jopie', 'Julien', 'Kees', 'Lee', 'Lucifer', 'Mickey', 'Mitchell', 'Mo', 'Mort', 'Noah', 'Nono', 'OH-HJ', 'Olaf', 'Olivier', 'Peter', 'Pik', 'Ronnie', 'Sjon', 'Steen', 'Stifler', 'Sukke', 'Tejoo K', 'van Rossum', 'Vondel', 'Willie', 'Winston', 'Woody', 'Sanana', 'Sietse']

const importAll = (require:any) =>
    require.keys().reduce((acc:any, next:any) => {
      acc[next.replace("./", "")] = require(next);
      return acc;
    }, {});
  
const images = importAll(
    require.context(".//huismokken", false, /\.(png|jpe?g|svg)$/)
);

export default class Spel extends Component<{},{currentScore:number, highScore:number, options:string[], name:string, huisgenoten:any, currentPlayer:any}>{
    constructor(props:any){
        super(props)
        let randoms = this.randomNames()
        this.state = {
            currentScore: 0,
            highScore: 0,
            options: randoms.knoppen,
            name: randoms.randomnaam,
            huisgenoten:[],
            currentPlayer:{huisnaam: 'anoniem'}
        }
    }

    componentDidMount(): void {
        this.getData()
    }

    getData(){
        const url = 'https://noordeinde-47.nl/eetlijst_api/mokkenspel.php'
        const requestOptions = {
            method: "GET",
            "Content-Type": "application/json",
          };
          fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                this.setState({huisgenoten: result.sort((a:any, b:any) => b.mokkenspelscore - a.mokkenspelscore)})
            })
    }

    getRandomInt(max:number) {
        return Math.floor(Math.random() * max);
    }

    randomNames(){
        let randomnaam = namen[this.getRandomInt(namen.length)]
        let i = 0
        let knoppen:string[] = []
        while(i < 4){
            let newknop = namen[this.getRandomInt(namen.length)]
            if(newknop != randomnaam && knoppen.includes(newknop) === false){
                knoppen.push(newknop)
                i += 1
            } 
        }
        knoppen.splice(this.getRandomInt(5), 0, randomnaam)
        return {
            knoppen: knoppen,
            randomnaam: randomnaam
        }
    }

    updatehghighscore(score:any){
        const url = 'https://noordeinde-47.nl/eetlijst_api/mokkenspel.php'
        const requestOptions = {
            method: "POST",
            body: JSON.stringify({
                ID:this.state.currentPlayer.ID,
                score: score
            })
          };
        fetch(url, requestOptions)
    }

    ronde(keuze:string){
        if(keuze == this.state.name){ // Het was goed
            let newscore = this.state.currentScore + 1
            if( newscore > this.state.highScore){
                this.setState({highScore: newscore})
                if(this.state.currentPlayer.huisnaam !== 'anoniem'){
                    this.updatehghighscore(newscore)
                }
            }
            this.setState({currentScore: newscore})
        }else{
            this.setState({currentScore: 0})
            alert('Dat was helaas niet goed')
        }
        let randoms = this.randomNames()
        this.setState({
            options: randoms.knoppen,
            name: randoms.randomnaam
        })
    }

    handlehgchange(e:any){
        this.setState({
            highScore: e.value.mokkenspelscore, 
            currentScore: 0, 
            currentPlayer:e.value
        }, () => {
            console.log(this.state.currentPlayer)
        })   
    }

    render(){
        return(
            <div>
                <div className="spel">
                    <Dropdown value={this.state.currentPlayer} onChange={(e) => this.handlehgchange(e)} options={this.state.huisgenoten} optionLabel="huisnaam" placeholder="Selecteer een huisgenoot"/>
                        <br />
                    <h3>Highscore: {this.state.highScore} | Score: {this.state.currentScore}</h3>
                    <img style={{width: '70%', margin: 'auto', aspectRatio: '1/1', objectFit: 'cover', borderRadius: '5px'}} src={images[this.state.name +".jpeg"]} alt="" />
                    <Button style={{marginTop: 10}} onClick={() => this.ronde(this.state.options[0])} label={this.state.options[0]}></Button>
                    <Button style={{marginTop: 10}} onClick={() => this.ronde(this.state.options[1])} label={this.state.options[1]}></Button>
                    <Button style={{marginTop: 10}} onClick={() => this.ronde(this.state.options[2])} label={this.state.options[2]}></Button>
                    <Button style={{marginTop: 10}} onClick={() => this.ronde(this.state.options[3])} label={this.state.options[3]}></Button>
                    <Button style={{marginTop: 10}} onClick={() => this.ronde(this.state.options[4])} label={this.state.options[4]}></Button>
                    
                </div>
                <div>
                    <div style={{width: '90%', maxWidth: '600px', backgroundColor: 'white', boxShadow: '0 0 10px gray', color: 'black', margin:'auto', marginTop:'50px', display:'flex', padding:'5px 30px', justifyContent:'space-between', borderRadius:'5px', textAlign:'center'}}>
                        <h4 style={{width:'100%'}}>Scorelijst</h4>            
                    </div>
                    {this.state.huisgenoten.map((huisgenoot:any, index:number) => {
                        return(
                            <div style={{width: '90%', maxWidth: '600px', backgroundColor: 'white', boxShadow: '0 0 10px gray', color: 'black', margin:'auto', marginTop:'10px', display:'flex', padding:'5px 30px', justifyContent:'space-between', borderRadius:'5px'}}>
                                <p>{huisgenoot.huisnaam}</p> <p>{huisgenoot.mokkenspelscore}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            
        )
    }
}